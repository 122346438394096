import Gigya from "../components/gigya/gigya";
import Navigation from "../components/navigation/navigation";
import Justscan from "../components/justscan/justscan";
import { setReferrerPolicy } from "../utils/commonScript";

export function initialize(component) {
    setReferrerPolicy("strict-origin-when-cross-origin");
    component.init();
}

export const initModule = (component) => {
    if (document.readyState !== "loading") {
        initialize(component);
    } else {
        document.addEventListener("DOMContentLoaded", function () {
            initialize(component);
        });
    }
};

initModule(Gigya);
initModule(Navigation);
initModule(Justscan);