import { redirect } from "../../utils/urlUtils";
import { deleteCookie, removeSessionStorage, setCookie, setSessionStorage } from "../../utils/storageUtils";

const Gigya = {
    initializeGigya() {
        let screensetCF = document.getElementById("gigya-screenset");
        if (screensetCF) {
            let gigyaAPIKey = document.querySelector(".cmp-contentfragment__element--gigyaAPIKey").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            let gigyasScreenSet = document.querySelector(".cmp-contentfragment__element--gigyaScreenSet").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            let gigyasStartScreen = document.querySelector(".cmp-contentfragment__element--gigyaStartScreen").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            let gigyaContainerID = document.querySelector(".cmp-contentfragment__element--gigyaContainerID").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();

            let gigyaWebSDK = document.createElement("script");

            gigyaWebSDK.type = "text/javascript";
            gigyaWebSDK.lang = "javascript";
            gigyaWebSDK.src = `https://cdns.gigya.com/js/gigya.js?apikey=${gigyaAPIKey}`;

            document.head.appendChild(gigyaWebSDK);
            gigyaWebSDK.addEventListener("load", () => {
                const gigya = window.gigya;
                if (gigya) {
                    gigya.accounts.showScreenSet({
                        screenSet: gigyasScreenSet,
                        startScreen: gigyasStartScreen,
                        containerID: gigyaContainerID,
                        lang: 'de',
                        onAfterScreenLoad: Gigya.handleScreenLoad,
                    });
                    gigya.accounts.addEventHandlers({
                        onLogin: Gigya.getAccountDetails,
                    });
                    gigya.accounts.getAccountInfo({
                        callback: Gigya.getAccountDetails,
                    });
                }
            });
        }
    },
    updateAuthToken(token) {
        if (token?.["id_token"] && window.gigyaAccDetails) {
            window.gigyaAccDetails["auth_token"] = token["id_token"];
        }
    },
    togglePassword() {
        const passwordContainers = document.querySelectorAll(".gigya-composite-control-password");
        passwordContainers.forEach((passwordContainer) => {
            const toggleIcon = document.createElement("span");
            toggleIcon.className = "toggle-password showPass";
            passwordContainer.appendChild(toggleIcon);

            toggleIcon.addEventListener("click", function () {
                var passwordInput = passwordContainer.querySelector(".gigya-input-password");
                if (toggleIcon.classList.contains("showPass")) {
                    toggleIcon.classList.add("hidePass");
                    toggleIcon.classList.remove("showPass");
                    passwordInput.setAttribute("type", "text");
                } else {
                    toggleIcon.classList.add("showPass");
                    toggleIcon.classList.remove("hidePass");
                    passwordInput.setAttribute("type", "password");
                }
            });
        });
    },
    handleScreenLoad() {
        Gigya.togglePassword();
    },
    handleLogout() {
        if (window.gigya) {
            window.gigya.accounts.logout({
                callback: function (response) {
                    if (response.errorCode === 0) {
                        window.gigyaAccDetails = {};
                        removeSessionStorage("verifiedTimestamp");
                        removeSessionStorage("retailerCodeInfo");
                        removeSessionStorage("thankYouErrorCode");
                        deleteCookie('userId');
                        redirect("welcome");
                    }
                },
            });
        }
    },
    getAccountDetails(account) {
        const { pathname } = window.location;
        const unbrandedPages = ["welcome", "registration", "forgot-password", "reset-password", "thank-you"];

        const verifiedTimestamp = account?.verifiedTimestamp;
        const retailerCodeInfo = account?.data?.registration?.retailer_id;
        verifiedTimestamp && setSessionStorage("verifiedTimestamp", verifiedTimestamp);
        retailerCodeInfo && setSessionStorage("retailerCodeInfo", retailerCodeInfo);

        if (account?.UID || window?.gigyaAccDetails?.UID) {
            window.gigyaAccDetails = account;
            setCookie('userId', account?.UID);

            window.gigya.accounts.getJWT({
                callback: Gigya.updateAuthToken,
            });

            if (pathname === "/" || unbrandedPages.some((page) => pathname.includes(page))) {
                redirect("home");
            }
        } else {
            if (pathname.includes("home")) {
                redirect("welcome");
            }
        }
    },
    init() {
        this.initializeGigya();
    },
};

export default Gigya;
